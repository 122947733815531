<template>
  <div class="container-fluid mt-5">
    <div>
      <card
        class="no-border-card"
        body-classes="px-0 pb-1"
        footer-classes="pb-2"
      >
        <template slot="header">
          <div class="row">
            <div class="col-6">
              <h3 class="mb-0">{{ $t("SUPPLIERS.SUPPLIERS_LIST") }}</h3>
            </div>
            <div class="col-6 text-right">
              <base-button
                type="primary"
                icon
                size="sm"
                @click="addSupplier()"
                v-if="$currentUserCan($permissions.PERM_CREATE_SUPPLIERS)"
              >
                <span class="btn-inner--icon">
                  <i class="fas fa-plus"></i>
                </span>
                <span class="btn-inner--text">{{
                  $t("SUPPLIERS.ADD_SUPPLIER")
                }}</span>
              </base-button>

              <notification-subscription
                :objectType="'suppliers'"
                :events="{
                  CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
            </div>
          </div>
        </template>

        <supplier-list-table />
      </card>
    </div>
  </div>
</template>
<script>
import { Button } from "element-ui";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import SupplierListTable from "./partials/SupplierListTable.vue";

export default {
  layout: "DashboardLayout",

  components: {
    SupplierListTable,
    NotificationSubscription,
    [Button.name]: Button,
  },

  methods: {
    addSupplier() {
      this.$router.push({ name: "Add Supplier" });
    },
  },
};
</script>
